import { images, stables } from "../../../../constants";
import Pagination from "../../../../components/Pagination";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useDataTable } from "../../../../hooks/useDataTable";
import DataTable from "../../components/DataTable";
import { deleteRecipe, getAllRecipes } from "../../../../services/index/recipes";

const ManageRecipes = () => {
  const {
    userState,
    currentPage,
    searchKeyword,
    data: recipesData,
    isLoading,
    isFetching,
    isLoadingDeleteData,
    queryClient,
    searchKeywordHandler,
    submitSearchKeywordHandler,
    deleteDataHandler,
    setCurrentPage,
  } = useDataTable({
    dataQueryFn: () => getAllRecipes(searchKeyword, currentPage),
    dataQueryKey: "recipes",
    deleteDataMessage: "Recipe is deleted",
    mutateDeleteFn: ({ slug, token }) => {
      return deleteRecipe({
        slug,
        token,
      });
    },
  });

  return (
    <DataTable
      pageTitle="Manage Recipes"
      dataListName="Recipes"
      searchInputPlaceHolder="Recipe title..."
      searchKeywordOnSubmitHandler={submitSearchKeywordHandler}
      searchKeywordOnChangeHandler={searchKeywordHandler}
      searchKeyword={searchKeyword}
      tableHeaderTitleList={["Title", "Category", "Created At", "Difficulty", "Preparation Time", "Season", ""]}
      isLoading={isLoading}
      isFetching={isFetching}
      data={recipesData?.data}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      headers={recipesData?.headers}
      userState={userState}
    >
      {recipesData?.data.map((recipe) => (
        <tr>
          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <a href="/" className="relative block">
                  <img
                    src={
                      recipe?.photo
                        ? stables.UPLOAD_FOLDER_BASE_URL + recipe?.photo
                        : images.samplePostImage
                    }
                    alt={recipe.title}
                    className="mx-auto object-cover rounded-lg w-10 aspect-square"
                  />
                </a>
              </div>
              <div className="ml-3">
                <p className="text-gray-900 whitespace-no-wrap">{recipe.title}</p>
              </div>
            </div>
          </td>
          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            <p className="text-gray-900 whitespace-no-wrap">
              {recipe.categories.length > 0
                ? recipe.categories
                    .slice(0, 3)
                    .map(
                      (category, index) =>
                        `${category.title}${
                          recipe.categories.slice(0, 3).length === index + 1
                            ? ""
                            : ", "
                        }`
                    )
                : "Uncategorized"}
            </p>
          </td>
          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            <p className="text-gray-900 whitespace-no-wrap">
              {new Date(recipe.createdAt).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </p>
          </td>
          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            <p className="text-gray-900 whitespace-no-wrap">
              {recipe.difficulty}
            </p>
          </td>
          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            <p className="text-gray-900 whitespace-no-wrap">
              {recipe.prepTime}mn
            </p>
          </td>
          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            <p className="text-gray-900 whitespace-no-wrap">
              {recipe.seasonal}
            </p>
          </td>

          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 space-x-5">
            <button
              disabled={isLoadingDeleteData}
              type="button"
              className="text-red-600 hover:text-red-900 disabled:opacity-70 disabled:cursor-not-allowed"
              onClick={() => {
                deleteDataHandler({
                  slug: recipe?.slug,
                  token: userState.userInfo.token,
                });
              }}
            >
              Delete
            </button>
            <Link
              to={`/admin/recipes/manage/edit/${recipe?.slug}`}
              className="text-green-600 hover:text-green-900"
            >
              Edit
            </Link>
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default ManageRecipes;
