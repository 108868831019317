import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import BreadCrumbs from "../../components/BreadCrumbs";
import CommentsContainer from "../../components/comments/CommentsContainer";
import MainLayout from "../../components/MainLayout";
import SocialShareButtons from "../../components/SocialShareButtons";
import { images, stables } from "../../constants";
import SuggestedPosts from "./container/SuggestedPosts";
import { useQuery } from "@tanstack/react-query";
import ArticleDetailSkeleton from "./components/ArticleDetailSkeleton";
import ErrorMessage from "../../components/ErrorMessage";
import { useSelector } from "react-redux";
import parseJsonToHtml from "../../utils/parseJsonToHtml";
import Editor from "../../components/editor/Editor";
import { getAllRecipes, getSingleRecipe } from "../../services/index/recipes";
import { PiCookingPotBold } from "react-icons/pi";
import { GiCookingGlove } from "react-icons/gi";
import { TbPointFilled } from "react-icons/tb";
import CommentsRecipeContainer from "../../components/comments/CommentsRecipeContainer";


const RecipeDetailPage = () => {
  const { slug } = useParams();
  const userState = useSelector((state) => state.user);
  const [breadCrumbsData, setbreadCrumbsData] = useState([]);
  const [servings, setServings] = useState(1);

  const { data, isLoading, isError } = useQuery({
    queryFn: () => getSingleRecipe({ slug }),
    queryKey: ["blog", slug],
    onSuccess: (data) => {
      setbreadCrumbsData([
        { name: "Home", link: "/" },
        { name: "Recipes", link: "/recipes" },
        { name: data.title, link: `/recipes/${data.slug}` },
      ]);
      setServings(data.servings)
    },
  });

  const { data: recipesData } = useQuery({
    queryFn: () => getAllRecipes(),
    queryKey: ["recipes"],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const handleServingsChange = (change) => {
    const newServings = servings + change;
    if (newServings >= 1) {
      setServings(newServings);
    }
  };


  return (
    <MainLayout>
      {isLoading ? (
        <ArticleDetailSkeleton />
      ) : isError ? (
        <ErrorMessage message="Couldn't fetch the recipe detail" />
      ) : (
        <section className="container mx-auto max-w-6xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
          <article className="flex-1">
            <BreadCrumbs data={breadCrumbsData} />
            <img
              className="rounded-xl w-full h-80 object-cover"
              src={
                data?.photo
                  ? stables.UPLOAD_FOLDER_BASE_URL + data?.photo
                  : images.samplePostImage
              }
              alt={data?.title}
            />
            <div className="flex gap-6 my-4">
              <div className="flex gap-2">
                {
                  data?.categories && data?.categories.length > 0 && 
                  data?.categories.map(category => <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{category.title}</span>)
                }
              </div>
              <div>
                {
                  data?.seasonal && <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                    {data?.seasonal == "summer" ? "Summer" : data?.seasonal == "winter" ? "Winter" : data?.seasonal == "automn" ? "Automn" : data?.seasonal == "spring" ? "Spring" : data?.seasonal == "all" ? "All Seasons" : ""}
                  </span>
                }
              </div>
            </div>


            <h1 className="text-5xl font-medium font-roboto mt-8 text-dark-hard md:text-[26px]">
              {data?.title}
            </h1>
            <div className="w-full">
              <p className="my-6">{data?.caption}</p>
              <div className="mb-6 w-full p-5 bg-blue-100 rounded-lg flex items-center justify-between">
                {/* Temps de préparation */}
                <div className="flex flex-col items-center">
                  <GiCookingGlove className="h-6 w-6 mr-2 text-gray-700" />
                  <p className="mt-2 text-gray-700"><strong>Preparation Time:</strong></p>
                  <p className="text-gray-700">{data?.prepTime} minutes</p>
                </div>
                {/* Temps de cuisson */}
                <div className="flex flex-col items-center">
                  <PiCookingPotBold className="h-6 w-6 ml-2 text-gray-700" />
                  <p className="mt-2 text-gray-700"><strong>Cooking Time:</strong></p>
                  <p className="text-gray-700">{data?.cookTime} minutes</p>
                </div>
                {/* Temps total */}
                <div className="flex flex-col items-center">
                  <p className="mt-2 text-gray-700"><strong>Total Time:</strong></p>
                  <p className="text-gray-700">{data.prepTime + data.cookTime} minutes</p>
                </div>
              </div>

              <div className="mb-8">
                {/* Servings */}
                {data?.servings && (
                  <div className="flex items-center mb-4">
                    <div className="flex flex-col items-center mr-4">
                      <p className="text-gray-700 mb-1"><strong>Servings:</strong></p>
                      <div className="flex items-center">
                        <button onClick={() => handleServingsChange(-1)} className="bg-blue-500 text-white rounded-full mr-2 hover:bg-blue-600 px-2 py-1 text-xs">-</button>
                        <p className="text-gray-700">{servings}</p>
                        <button onClick={() => handleServingsChange(1)} className="bg-blue-500 text-white rounded-full ml-2 hover:bg-blue-600 px-2 py-1 text-xs">+</button>
                      </div>
                    </div>
                  </div>
                )}

                {/* Ingredients */}
                <div className="mb-4">
                  <h2 className="text-gray-700 mb-2 text-xl"><strong>Ingredients:</strong></h2>
                  <ul className="max-w-md space-y-2 list-disc">
                    {data?.ingredients.map((ingredient, index) => (
                      <li key={index} className="flex items-center text-gray-700">
                        <TbPointFilled /> {ingredient.quantity} {ingredient.unit} {ingredient.name}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Instructions */}
                <div className="pt-6">
                  <h2 className="text-gray-700 mb-2 text-xl"><strong>Instructions:</strong></h2>
                  <ol className="max-w-md space-y-2 list-decimal">
                    {data?.instructions.map((instruction, index) => (
                      <li key={index} className="flex items-center text-gray-700 pb-2">
                        {index+1}. {instruction}
                      </li>
                    ))}
                  </ol>
                </div>
              </div>

            </div>
            <CommentsRecipeContainer
              comments={data?.comments}
              className="mt-10"
              logginedUserId={userState?.userInfo?._id}
              postSlug={slug}
            />
          </article>
          <div>
            <SuggestedPosts
              header="Latest Recipes"
              posts={recipesData?.data.slice(0, 3)}
              tags={data?.tags}
              className="mt-8 lg:mt-0 lg:max-w-xs"
            />
            <div className="mt-7">
              <h2 className="font-roboto font-medium text-dark-hard mb-4 md:text-xl">
                Share on:
              </h2>
              <SocialShareButtons
                url={encodeURI(window.location.href)}
                title={encodeURIComponent(data?.title)}
              />
            </div>
          </div>
        </section>
      )}
    </MainLayout>
  );
};

export default RecipeDetailPage;
