import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { getAllPosts } from "../../services/index/posts";
import ArticleCardSkeleton from "../../components/ArticleCardSkeleton";
import ErrorMessage from "../../components/ErrorMessage";
import ArticleCard from "../../components/ArticleCard";
import MainLayout from "../../components/MainLayout";
import Pagination from "../../components/Pagination";
import { useSearchParams } from "react-router-dom";
import Search from "../../components/Search";
import { getAllRecipes } from "../../services/index/recipes";
import RecipeCard from "../../components/RecipeCard";
import { getAllCategories } from "../../services/index/recipeCategories";

let isFirstRun = true;

const RecipesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [difficultyFilter, setDifficultyFilter] = useState("");
  const [seasonFilter, setSeasonFilter] = useState("");
  const [totalTimeFilter, setTotalTimeFilter] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const ingredientList = [
    {
      title: "Fruits et Légumes",
      ingredients: ["Pommes", "Poires", "Bananes", "Carottes", "Salade", "Tomates"]
    },
    {
      title: "Viandes et Poissons",
      ingredients: ["Poulet", "Boeuf", "Poisson", "Crevettes", "Saumon"]
    },
    {
      title: "Produits de Boulangerie",
      ingredients: ["Farine", "Sucre", "Oeufs", "Levure", "Beurre"]
    }
  ];

  const searchParamsValue = Object.fromEntries([...searchParams]);

  const currentPage = parseInt(searchParamsValue?.page) || 1;
  const searchKeyword = searchParamsValue?.search || "";

  const { data, isLoading, isError, isFetching, refetch } = useQuery({
    queryFn: () => getAllRecipes(searchKeyword, currentPage, 12, selectedCategory),
    queryKey: ["recipes", searchKeyword, currentPage, selectedCategory],
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
  });
  
  const { data: recipesData } = useQuery({
    queryFn: () => getAllCategories(),
    queryKey: ["categories"],
  });

  console.log(data);
  console.log(recipesData);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isFirstRun) {
      isFirstRun = false;
      return;
    }
    refetch();
  }, [currentPage, searchKeyword, refetch]);

  const handlePageChange = (page) => {
    setSearchParams({ page, search: searchKeyword });
  };

  const handleSearch = ({ searchKeyword }) => {
    setSearchParams({ page: 1, search: searchKeyword });
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    setSearchParams({ page: 1, search: searchKeyword, category: categoryId });
  };

  const clearFilters = () => {
    setSelectedCategory("");
    setDifficultyFilter("");
    setSeasonFilter("");
    setTotalTimeFilter("");
    setSelectedIngredients([]);
    setSearchParams({ page: 1, search: "" });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleIngredientToggle = (ingredient) => {
    if (selectedIngredients.includes(ingredient)) {
      setSelectedIngredients(selectedIngredients.filter((item) => item !== ingredient));
    } else {
      setSelectedIngredients([...selectedIngredients, ingredient]);
    }
  };


  const calculateTotalTime = (recipe) => {
    const prepTime = recipe.prepTime || 0;
    const cookTime = recipe.cookTime || 0;
    return prepTime + cookTime;
  };

  const filteredRecipes = data?.data.filter((recipe) => {
    const matchIngredients = selectedIngredients.every((ingredient) =>
      recipe.ingredients.some((recipeIngredient) => recipeIngredient.name === ingredient)
    );
    
    const matchDifficulty = !difficultyFilter || recipe.difficulty === difficultyFilter;
    const matchSeason = !seasonFilter || recipe.seasonal === seasonFilter;
    const matchTotalTime = !totalTimeFilter || calculateTotalTime(recipe) <= totalTimeFilter;
    
    return matchIngredients && matchDifficulty && matchSeason && matchTotalTime;
  });
  

  return (
    <MainLayout>
      <section className="flex flex-col container mx-auto px-5 py-10">
        <Search
          className="w-full max-w-xl mb-10"
          onSearchKeyword={handleSearch}
        />
        <div>
          <div className="flex items-center justify-center mb-6 gap-4">
            {recipesData?.data?.map((category) => (
              <span 
                key={category._id} 
                className={`text-gray-50 text-lg font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 cursor-pointer`}
                style={{ backgroundColor: category?.color }}
                onClick={() => handleCategoryClick(category._id)}
              >
                {category.title}
              </span>
            ))}
          </div>
          <div class="flex gap-4">
            {/* DIFFICULTY */}
            <div className="md:w-1/5 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="difficulty">
                Difficulty
              </label>
              <select 
                value={difficultyFilter} 
                onChange={(e) => setDifficultyFilter(e.target.value)}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"
              >
                <option value="">All Difficulties</option>
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
              </select>
            </div>

            {/* SEASONS */}
            <div className="md:w-1/5 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="difficulty">
                Seasons
              </label>
              <select 
                value={seasonFilter} 
                onChange={(e) => setSeasonFilter(e.target.value)}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"
              >
                <option value="">All seasons</option>
                <option value="winter">Winter</option>
                <option value="spring">Spring</option>
                <option value="summer">Summer</option>
                <option value="automn">Automn</option>
              </select>
            </div>

            {/* PREPARATION TIME */}
            <div className="md:w-1/5 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="difficulty">
                Preparation Time
              </label>
              <select
                value={totalTimeFilter}
                onChange={(e) => setTotalTimeFilter(parseInt(e.target.value))}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"
              >
                <option value="">Any</option>
                <option value="15">Less than 15 minutes</option>
                <option value="30">Less than 30 minutes</option>
                <option value="60">Less than 1 hour</option>
                <option value="90">Less than 1.5 hours</option>
              </select>
            </div>

            {/* INGREDIENTS LIST */}
            <div className="md:w-1/3 relative inline-block text-left">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="difficulty">
                Ingredients list
              </label>
              <div>
                <button
                  onClick={toggleDropdown}
                  type="button"
                  className="appearance-none inline-flex items-center justify-between w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                  id="options-menu"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  Filter Ingredients
                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {dropdownOpen && (
                <div
                  className="origin-top-left absolute left-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <div className="py-1 px-6 py-6 flex justify-center">
                    {ingredientList.map((column, columnIndex) => (
                      <div key={columnIndex} className="flex flex-col mr-4">
                        <p className="text-md font-semibold mb-2">{column.title}</p>
                        {column.ingredients.map((ingredient, index) => (
                          <label key={index} className="flex items-center py-2">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-indigo-600"
                              value={ingredient}
                              checked={selectedIngredients.includes(ingredient)}
                              onChange={() => handleIngredientToggle(ingredient)}
                            />
                            <span className="ml-2 text-sm text-gray-700">{ingredient}</span>
                          </label>
                        ))}
                      </div>
                    ))}
                  </div>  
                </div>
              )}
            </div>
          </div>
        </div>
        
        <button onClick={clearFilters} className="text-gray-50 text-lg w-1/10 mx-auto mb-10 font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 cursor-pointer bg-gray-300">
          Clear Filters
        </button>
        
        <div className=" flex flex-wrap md:gap-x-5 gap-y-5 pb-10">
          {isLoading || isFetching ? (
            [...Array(3)].map((item, index) => (
              <ArticleCardSkeleton
                key={index}
                className="w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]"
              />
            ))
          ) : isError ? (
            <ErrorMessage message="Couldn't fetch the posts data" />
          ) : filteredRecipes.length === 0 ? (
            <p className="text-orange-500">No Recipes Found!</p>
          ) : (
            filteredRecipes.map((recipe) => (
              <RecipeCard
                key={recipe._id}
                post={recipe}
                className="w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]"
              />
            ))
          )}
        </div>
        {!isLoading && (
          <Pagination
            onPageChange={(page) => handlePageChange(page)}
            currentPage={currentPage}
            totalPageCount={JSON.parse(data?.headers?.["x-totalpagecount"])}
          />
        )}
      </section>
    </MainLayout>
  );
};

export default RecipesPage;
