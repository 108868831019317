import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getSingleCategory,
  updateCategory,
} from "../../../../services/index/recipeCategories";
import { CirclePicker } from 'react-color';

const EditRecipeCategories = () => {
  const queryClient = useQueryClient();
  const [categoryTitle, setCategoryTitle] = useState("");
  const [color, setColor] = useState("");
  const navigate = useNavigate();
  const { slug } = useParams();
  const userState = useSelector((state) => state.user);

  const { isLoading, isError } = useQuery({
    queryFn: () => getSingleCategory({ slug }),
    queryKey: ["categories", slug],
    onSuccess: (data) => {
      console.log(data)
      setCategoryTitle(data?.title);
      setColor(data?.color);
    },
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateUpdateCategory, isLoading: isLoadingUpdateCategory } =
    useMutation({
      mutationFn: ({ title, color, slug, token }) => {
        return updateCategory({
          title,
          color, 
          slug,
          token,
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(["categories", slug]);
        toast.success("Category is updated");
        navigate(`/admin/recipe-categories/manage/edit/${data._id}`, {
          replace: true,
        });
      },
      onError: (error) => {
        toast.error(error.message);
        console.log(error);
      },
    });

  const handleUpdateCategory = () => {
    if (!categoryTitle && !color) return;
    mutateUpdateCategory({
      title: categoryTitle,
      color,
      slug,
      token: userState.userInfo.token,
    });
  };

  return (
    <div className="col-span-4 py-8">
      <h4 className="text-lg leading-tight">Update Category</h4>
      <div className="d-form-control w-full mt-6">
        <input
          value={categoryTitle}
          className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"
          onChange={(e) => setCategoryTitle(e.target.value)}
          placeholder="Category Title"
        />
        <div className="mt-6">
          <CirclePicker
            width={"100%"}
            triangle="hide"
            onChange={(color) => { setColor(color.hex) }}
            color={color}
            circleSpacing={18}
            colors={
              [
                '#FFD700', '#FCB900', '#FFA07A', '#FF7F50', '#FF6347', '#FF6900', '#FF4500', '#EB144C', 
                '#D61F69', '#FF69B4', '#F78DA7', '#9370DB', '#9900EF', '#0693E3', '#1E90FF', '#8ED1FC', 
                '#87CEEB', '#00CED1', '#7BDCB5', '#00D084', '#32CD32', '#ABB8C3', 
              ]
            }
          />
        </div>

        <button
          disabled={isLoadingUpdateCategory || isLoading || isError}
          type="button"
          onClick={handleUpdateCategory}
          className="w-fit mt-6 bg-green-500 text-white font-semibold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
        >
          Update Category
        </button>
      </div>
    </div>
  );
};

export default EditRecipeCategories;
