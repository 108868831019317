import React, { useState } from 'react'
import { getImagesInFolder, getSingleFolder, uploadImageToFolder } from '../../../../services/index/gallery';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { images, stables } from '../../../../constants';
import toast from 'react-hot-toast';

const GalleryDetailPage = () => {
  const { id } = useParams();
  const userState = useSelector((state) => state.user);
  const [breadCrumbsData, setbreadCrumbsData] = useState([]);

  const { data, isLoading, isError } = useQuery({
    queryFn: () => getSingleFolder({ id }),
    queryKey: ["folders", id],
    onSuccess: (data) => {
      setbreadCrumbsData([
        { name: "Gallery", link: "/admin/gallery" },
        { name: data.name, link: `/admin/gallery/${data._id}` },
      ]);
      console.log(data)
    },
    refetchOnWindowFocus: false,
  });

  
  const handleAddImage = async (folderId) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.style.display = 'none';

    document.body.appendChild(fileInput);

    fileInput.addEventListener("change", async () => {
        if (fileInput.files.length === 0) {
            toast.error("Aucune image sélectionnée.");
            document.body.removeChild(fileInput);
            return;
        }

        const imageFile = fileInput.files[0];
        console.log("File selected:", imageFile.name); // Debug: log selected file name

        try {
            toast.loading("Uploading image...");

            console.log("Preparing to upload image"); // Debug: confirm reaching this point
            const response = await uploadImageToFolder({
                token: userState.userInfo.token,
                folderId,
                image: imageFile
            });

            console.log("Image uploaded response:", response); // Debug: log response from the server

            toast.dismiss();
            toast.success("Image ajoutée avec succès !");
        } catch (error) {
            console.error("Upload error:", error); // Debug: log any errors
            toast.dismiss();
            toast.error("Erreur lors de l'ajout de l'image : " + error.message);
        } finally {
            document.body.removeChild(fileInput);
        }
    }, false);

    fileInput.click();
};



  return (
    <div>
      <h1 className="text-2xl font-semibold">Gallery</h1>

      <div className="w-full px-4 mx-auto">
        <div className="py-8">
          <div className="flex flex-row justify-between w-full mb-1 sm:mb-0">
            <h2 className="text-2xl leading-tight">{data?.name}</h2>
            <button
              onClick={() => handleAddImage(id)}
              className="text-blue-600 hover:text-blue-900"
            >
              Ajouter une image
            </button>
          </div>
          <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8 flex gap-8">
            {data?.images.map((image) => (
              <img key={image} src={
                image
                ? stables.UPLOAD_FOLDER_BASE_URL + image
                : images.samplePostImage
              }/>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GalleryDetailPage