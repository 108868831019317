import { images, stables } from "../../../../constants";
import Pagination from "../../../../components/Pagination";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useDataTable } from "../../../../hooks/useDataTable";
import DataTable from "../../components/DataTable";
import { createFolder, deleteFolder, getFolders, updateFolder, uploadImageToFolder } from "../../../../services/index/gallery";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FaEdit, FaFolder, FaTrash } from "react-icons/fa";
import { useEffect, useState } from "react";

const GalleryPage = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const [editFolderName, setEditFolderName] = useState("");
  const [editFolderColor, setEditFolderColor] = useState("");
  const [editFolderId, setEditFolderId] = useState(null);
  const [editFolderData, setEditFolderData] = useState({ name: "", color: "" });
  const [openFolders, setOpenFolders] = useState({});
  

  const {
    data: foldersData,
    isLoadingDeleteData,
    isLoading,
    isFetching,
    queryClient,
    deleteDataHandler
  } = useDataTable({
    dataQueryFn: () => getFolders(),
    dataQueryKey: "folders",
    deleteDataMessage: "Folder is deleted",
    mutateDeleteFn: ({ slug, token }) => {
      return deleteFolder({
        slug,
        token,
      });
    },
  });

  useEffect(() => {
    if (foldersData?.data) {
      const folderIds = foldersData.data.map(folder => folder._id);
      const initialOpenFolders = {};
      folderIds.forEach(folderId => {
        initialOpenFolders[folderId] = false; // Initialiser tous les dossiers comme fermés
      });
      setOpenFolders(initialOpenFolders);
    }
  }, [foldersData]);

  const { mutate: mutateCreateFolder, isLoading: isLoadingCreateFolder } =
    useMutation({
      mutationFn: ({ token }) => {
        return createFolder({
          token,
          name: 'Nouveau Dossier',
          color: '#FOFOFO'
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(["folders"]);
        toast.success("Folder is created, edit that now!");
        navigate(`/admin/gallery`);
      },
      onError: (error) => {
        toast.error(error.message);
        console.log(error);
      },
    });

  const handleCreateNewFolder = ({ token }) => {
    mutateCreateFolder({ token });
  };

  const handleEditFolder = (folderId, folderName, folderColor) => {
    setEditFolderId(folderId);
    setEditFolderName(folderName);
    setEditFolderColor(folderColor);
  };

  const handleUpdateFolder = async () => {
    try {
      await updateFolder({
        folderId: editFolderId,
        name: editFolderName,
        color: editFolderColor,
        token: userState.userInfo.token,
      });
      queryClient.invalidateQueries("folders");
      toast.success("Folder updated successfully");
      setEditFolderId(null);
    } catch (error) {
      toast.error("Error updating folder");
      console.error(error);
    }
  };

  const handleToggleFolder = (folderId) => {
    setOpenFolders(prevOpenFolders => ({ ...prevOpenFolders, [folderId]: !prevOpenFolders[folderId] }));
  };

  // const handleAddImage = async (folderId) => {
  //   try {
  //     const fileInput = document.createElement("input");
  //     fileInput.type = "file";
  //     fileInput.accept = "image/*";

  //     // Écouter le changement de fichier sélectionné
  //     fileInput.addEventListener("change", async () => {
  //       const imageFile = fileInput.files[0];

  //       // Vérifier si un fichier a été sélectionné
  //       if (!imageFile) {
  //         toast.error("Aucune image sélectionnée.");
  //         return;
  //       }
  //       const response = await uploadImageToFolder({
  //         token: userState.userInfo.token,
  //         folderId: folderId,
  //         image: imageFile,
  //       });
  //       if (response.success) {
  //           toast.success("Image ajoutée avec succès !");
  //       } else {
  //           toast.error(response.message || "Erreur lors de l'ajout de l'image.");
  //       }
  //     });

  //     // Cliquez sur le bouton d'élément de fichier invisible pour ouvrir la boîte de dialogue de sélection de fichier
  //     fileInput.click();
  //   } catch (error) {
  //     toast.error("Une erreur est survenue lors de l'ajout de l'image.");
  //     console.error(error);
  //   }
  // };

  return (
    <div>
      <h1 className="text-2xl font-semibold">Manage Gallery</h1>

      <div className="w-full px-4 mx-auto">
        <div className="py-8">
          <div className="flex flex-row justify-between w-full mb-1 sm:mb-0">
            <h2 className="text-2xl leading-tight">Gallery</h2>
            <button
              disabled={isLoadingCreateFolder}
              className="text-start disabled:opacity-60 disabled:cursor-not-allowed"
              onClick={() =>
                handleCreateNewFolder({ token: userState.userInfo.token })
              }
            >
              Add New Folder
            </button>
          </div>
          <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8 flex gap-8">
            {foldersData?.data.map((folder) => (
              <div key={folder._id} className="folder-item">
                <FaFolder className="text-9xl mr-2" style={{ color: folder.color }} onClick={() => navigate(`/admin/gallery/${folder._id}`)} />
                {editFolderId === folder._id ? (
                  <div>
                    <input
                      type="text"
                      value={editFolderName}
                      onChange={(e) => setEditFolderName(e.target.value)}
                    />
                    <input
                      type="color"
                      value={editFolderColor}
                      onChange={(e) => setEditFolderColor(e.target.value)}
                    />
                    <button
                      className="text-blue-600 hover:text-blue-900"
                      onClick={handleUpdateFolder}
                    >
                      Update Folder
                    </button>
                  </div>
                ) : (
                  <h3 onClick={() => handleToggleFolder(folder._id)}>{folder.name}</h3>
                )}
                <button
                  disabled={isLoadingDeleteData}
                  type="button"
                  className="text-red-600 hover:text-red-900 disabled:opacity-70 disabled:cursor-not-allowed"
                  onClick={() => {
                    deleteDataHandler({
                      slug: folder?._id,
                      token: userState.userInfo.token,
                    });
                  }}
                >
                  <FaTrash/>
                </button>
                <button
                  className="text-green-600 hover:text-green-900"
                  onClick={() => handleEditFolder(folder._id, folder.name, folder.color)}
                >
                  <FaEdit/>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryPage;
