import axios from "axios";

export const getFolders = async () => {
  try {
    const { data, headers } = await axios.get("https://blog-back-wy7a.onrender.com/api/folders");
    return { data, headers };
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const deleteFolder = async ({ slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let folderId = slug;

    const { data } = await axios.delete(`https://blog-back-wy7a.onrender.com/api/folders/${folderId}`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const createFolder = async ({ token, name, color }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.post(
      `https://blog-back-wy7a.onrender.com/api/folders`,
      { name, color },
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const updateFolder = async ({ token, name, color, folderId }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.put(
      `https://blog-back-wy7a.onrender.com/api/folders/${folderId}`,
      { name, color },
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const getSingleFolder = async ({ id }) => {
  try {
    const { data } = await axios.get(`https://blog-back-wy7a.onrender.com/api/folders/${id}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const uploadImageToFolder = async ({ token, folderId, image }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.post(`https://blog-back-wy7a.onrender.com/api/folders/${folderId}/upload`, image, config);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const getImagesInFolder = async ({ folderId }) => {
  console.log(folderId)
  try {
    const { data } = await axios.get(`https://blog-back-wy7a.onrender.com/api/folders/${folderId}`);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const deleteImage = async ({ token, imageId }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.delete(`https://blog-back-wy7a.onrender.com/api/images/${imageId}`, config);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};
