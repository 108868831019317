import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { Link, useParams, useNavigate } from "react-router-dom";
import ArticleDetailSkeleton from "../../../articleDetail/components/ArticleDetailSkeleton";
import ErrorMessage from "../../../../components/ErrorMessage";
import { stables } from "../../../../constants";
import { HiOutlineCamera } from "react-icons/hi";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Editor from "../../../../components/editor/Editor";
import MultiSelectTagDropdown from "../../components/select-dropdown/MultiSelectTagDropdown";
import { getAllCategories } from "../../../../services/index/recipeCategories";
import {
  categoryToOption,
  filterCategories,
} from "../../../../utils/multiSelectTagUtils";
import { getSingleRecipe, updateRecipe } from "../../../../services/index/recipes";
import { MdAdd, MdDeleteOutline } from "react-icons/md";

const promiseOptions = async (inputValue) => {
  const { data: categoriesData } = await getAllCategories();
  return filterCategories(inputValue, categoriesData);
};

const EditRecipe = () => {
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const [initialPhoto, setInitialPhoto] = useState(null);
  const [photo, setPhoto] = useState(null);

  const [servings, setServings] = useState(1);
  const [prepTime, setPrepTime] = useState(1);
  const [cookTime, setCookTime] = useState(1);
  const [difficulty, setDifficulty] = useState("Facile");
  const [seasonal, setSeasonal] = useState("");
  const [categories, setCategories] = useState(null);
  const [externalLinks, setExternalLinks] = useState(null);
  const [ingredients, setIngredients] = useState([{ name: '', quantity: '', unit: '' }]);
  const [instructions, setInstructions] = useState(['']);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState(null);
  const [recipeSlug, setRecipeSlug] = useState(slug);
  const [caption, setCaption] = useState("");

  const { data, isLoading, isError } = useQuery({
    queryFn: () => getSingleRecipe({ slug }),
    queryKey: ["blog", slug],
    onSuccess: (data) => {
      setInitialPhoto(data?.photo);
      setCategories(data.categories.map((item) => item._id));
      setTitle(data.title);
      setServings(data.servings);
      setPrepTime(data.prepTime);
      setCookTime(data.cookTime);
      setDifficulty(data.difficulty);
      setSeasonal(data.seasonal);
      setInstructions(data.instructions)
      setIngredients(data.ingredients)
    },
    refetchOnWindowFocus: false,
  });

  const {
    mutate: mutateUpdateRecipeDetail,
    isLoading: isLoadingUpdateRecipeDetail,
  } = useMutation({
    mutationFn: ({ updatedData, slug, token }) => {
      return updateRecipe({
        updatedData,
        slug,
        token,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["blog", slug]);
      toast.success("Recipe is updated");
      navigate(`/admin/recipes/manage/edit/${data.slug}`, { replace: true });
    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
  };

  const handleUpdateRecipe = async () => {
    let updatedData = new FormData();

    if (!initialPhoto && photo) {
      updatedData.append("postPicture", photo);
    } else if (initialPhoto && !photo) {
      const urlToObject = async (url) => {
        let reponse = await fetch(url);
        let blob = await reponse.blob();
        const file = new File([blob], initialPhoto, { type: blob.type });
        return file;
      };
      const picture = await urlToObject(
        stables.UPLOAD_FOLDER_BASE_URL + data?.photo
      );

      updatedData.append("postPicture", picture);
    }

    updatedData.append(
      "document",
      JSON.stringify({ categories, title, tags, slug: recipeSlug, caption, difficulty, servings, prepTime, cookTime, externalLinks, seasonal, instructions, ingredients })
    );

    mutateUpdateRecipeDetail({
      updatedData,
      slug,
      token: userState.userInfo.token,
    });
  };

  const handleDeleteImage = () => {
    if (window.confirm("Do you want to delete your Recipe picture?")) {
      setInitialPhoto(null);
      setPhoto(null);
    }
  };

  const addInstruction = () => {
    setInstructions([...instructions, '']);
  };

  const removeInstruction = (index) => {
    const newInstructions = [...instructions];
    newInstructions.splice(index, 1);
    setInstructions(newInstructions);
  };

  const handleInstructionChange = (index, value) => {
    const newInstructions = [...instructions];
    newInstructions[index] = value;
    setInstructions(newInstructions);
  };

  const addIngredient = () => {
    setIngredients([...ingredients, { name: '', quantity: '', unit: '' }]);
  };

  const removeIngredient = (index) => {
    const newIngredients = [...ingredients];
    newIngredients.splice(index, 1);
    setIngredients(newIngredients);
  };

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index][field] = value;
    setIngredients(newIngredients);
  };


  let isRecipeDataLoaded = !isLoading && !isError;

  return (
    <div>
      {isLoading ? (
        <ArticleDetailSkeleton />
      ) : isError ? (
        <ErrorMessage message="Couldn't fetch the recipe detail" />
      ) : (
        <section className="container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
          <article className="flex-1">
            <label htmlFor="postPicture" className="w-full cursor-pointer">
              {photo ? (
                <img
                  src={URL.createObjectURL(photo)}
                  alt={data?.title}
                  className="rounded-xl w-full"
                />
              ) : initialPhoto ? (
                <img
                  src={stables.UPLOAD_FOLDER_BASE_URL + data?.photo}
                  alt={data?.title}
                  className="rounded-xl w-full"
                />
              ) : (
                <div className="w-full min-h-[200px] bg-blue-50/50 flex justify-center items-center">
                  <HiOutlineCamera className="w-7 h-auto text-primary" />
                </div>
              )}
            </label>
            <input
              type="file"
              className="sr-only"
              id="postPicture"
              onChange={handleFileChange}
            />
            <button
              type="button"
              onClick={handleDeleteImage}
              className="w-fit bg-red-500 text-sm text-white font-semibold rounded-lg px-2 py-1 mt-5"
            >
              Delete Image
            </button>
            <div className="mt-4 flex gap-2">
              {data?.categories.map((category) => (
                <Link
                  to={`/blog?category=${category.name}`}
                  className="text-primary text-sm font-roboto inline-block md:text-base"
                >
                  {category.name}
                </Link>
              ))}
            </div>

            <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center">
              <h2 className="md:w-1/5 max-w-sm block uppercase tracking-wide text-grey-darker text-md font-bold mb-2">Basic Informations</h2>
              <div className="md:w-2/3 max-w-md mx-auto">
                <div className="md:w-full mx-auto space-y-5">

                  {/* Title */}
                  <div className="md:w-full px-3">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="title">
                      Title
                    </label>
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
                      id="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="title"
                    />
                  </div>

                  {/* Caption */}
                  <div className="md:w-full px-3">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="caption">
                      Caption
                    </label>
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
                      id="caption"
                      value={caption}
                      onChange={(e) => setCaption(e.target.value)}
                      placeholder="caption"
                    />
                  </div>

                  {/* Slug */}
                  <div className="md:w-full px-3">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="slug">
                      Slug
                    </label>
                    <input
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
                      id="slug"
                      value={recipeSlug}
                      onChange={(e) => setRecipeSlug(e.target.value.replace(/\s+/g, "-").toLowerCase())}
                      placeholder="slug"
                    />
                  </div>
                  
                  {/* Servings */}
                  <div className="md:w-full px-3">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="servings">
                      Servings
                    </label>
                    <input
                      type='number'
                      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3" 
                      id="servings"
                      value={servings}
                      onChange={(e) => setServings(e.target.value)}
                      placeholder="servings"
                    />
                  </div>

                  <div className="md:w-full md:flex">
                    {/* Preparation Time */}
                    <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-first-name">
                      Preparation Time
                      </label>
                      <input 
                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" 
                        id="prepTime" 
                        type="number"
                        min={0}
                        value={prepTime}
                        onChange={(e) => setPrepTime(e.target.value)}
                        placeholder="prepTime"
                      />
                    </div>

                    {/* Cooking Time */}
                    <div className="md:w-1/2 px-3">
                      <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-last-name">
                      Cooking Time
                      </label>
                      <input 
                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                        id="cookTime" 
                        min={0}
                        value={cookTime}
                        type="number"
                        onChange={(e) => setCookTime(e.target.value)}
                        placeholder="cookTime"
                      />
                    </div>
                  </div>

                  <div className="md:w-full md:flex">
                    {/* Difficulty */}
                    <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="difficulty">
                        Difficulty
                      </label>
                      <select
                        value={difficulty}
                        onChange={(e) => setDifficulty(e.target.value)}
                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"
                      >
                        <option value="">Select Difficulty</option>
                        <option value="easy">Easy</option>
                        <option value="medium">Medium</option>
                        <option value="hard">Hard</option>
                      </select>
                    </div>

                    {/* Seasonal */}
                    <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="seasonal">
                        Seasonal
                      </label>
                      <select
                        value={seasonal}
                        onChange={(e) => setSeasonal(e.target.value)}
                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"
                      >
                        <option value="">Select Seasonal</option>
                        <option value="winter">Winter</option>
                        <option value="spring">Spring</option>
                        <option value="summer">Summer</option>
                        <option value="automn">Automn</option>
                        <option value="all">All seasons</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr/>


            {/* Categories, Tags & External Links */}
            <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center">
              <h2 className="md:w-1/5 max-w-sm block uppercase tracking-wide text-grey-darker text-md font-bold mb-2">Categories, Tags & External Links</h2>
              <div className="md:w-2/3 max-w-md mx-auto">
                <div className="md:w-full mx-auto space-y-5">
                  {/* Categories */}
                  <div className="md:w-full px-3">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="categories">
                      Categories
                    </label>
                    {isRecipeDataLoaded && (
                      <MultiSelectTagDropdown
                        loadOptions={promiseOptions}
                        defaultValue={data.categories.map(categoryToOption)}
                        onChange={(newValue) =>
                          setCategories(newValue.map((item) => item.value))
                        }
                      />
                    )}
                  </div>

                  {/* Tags */}
                  <div className="md:w-full px-3">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="tags">
                      Tags
                    </label>
                    {isRecipeDataLoaded && (
                      <CreatableSelect
                        defaultValue={data.tags.map((tag) => ({
                          value: tag,
                          label: tag,
                        }))}
                        isMulti
                        onChange={(newValue) =>
                          setTags(newValue.map((item) => item.value))
                        }
                        className="relative z-20"
                      />
                    )}
                  </div>

                  {/* External Links */}
                  <div className="md:w-full px-3 mb-3">
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="external-links">
                      External Links
                    </label>
                    {isRecipeDataLoaded && (
                      <CreatableSelect
                        defaultValue={data.externalLinks.map((link) => ({
                          value: link,
                          label: link,
                        }))}
                        isMulti
                        onChange={(newValue) =>
                          setExternalLinks(newValue.map((item) => item.value))
                        }
                        className="relative z-20"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          
            <hr />

            {/* Ingredients */}
            <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center">
              <h2 className="md:w-1/5 max-w-sm block uppercase tracking-wide text-grey-darker text-md font-bold mb-2">Ingredients</h2>
              <div className="md:w-2/3 max-w-md mx-auto">
                <div className="md:w-full mx-auto space-y-5">
                  <div className="md:w-full px-3">
                    {ingredients.map((ingredient, index) => (
                      <div key={index} className="flex items-center mb-2">
                        <input
                          type="number"
                          min={0}
                          value={ingredient.quantity}
                          onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)}
                          placeholder="Quantity"
                          className="mr-2 py-2 px-3 border rounded w-20"
                        />
                        <input
                          type="text"
                          value={ingredient.unit}
                          onChange={(e) => handleIngredientChange(index, 'unit', e.target.value)}
                          placeholder="Unit"
                          className="mr-2 py-2 px-3 border rounded w-20"
                        />
                        <input
                          type="text"
                          value={ingredient.name}
                          onChange={(e) => handleIngredientChange(index, 'name', e.target.value)}
                          placeholder="Ingredient Name"
                          className="mr-2 py-2 px-3 border rounded w-full"
                        />
                        <button
                          type="button"
                          onClick={() => removeIngredient(index)}
                          className="inline-flex items-center justify-center w-12 h-8 mr-2 text-pink-100 transition-colors duration-150 bg-red-500 rounded-lg focus:shadow-outline hover:bg-red-600"
                        >
                          <MdDeleteOutline />
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={addIngredient}
                      className="bg-green-500 text-white py-2 px-2 rounded-full hover:bg-green-600"
                    >
                      <MdAdd />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            {/* Instructions */}
            <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center">
              <h2 className="md:w-1/5 max-w-sm block uppercase tracking-wide text-grey-darker text-md font-bold mb-2">Instructions</h2>
              <div className="md:w-2/3 max-w-md mx-auto">
                <div className="md:w-full mx-auto space-y-5">
                  <div className="md:w-full px-3">
                    {instructions.map((instruction, index) => (
                      <div key={index} className="flex items-center mb-2">
                        {index+1} <input
                          type="text"
                          value={instruction}
                          onChange={(e) => handleInstructionChange(index, e.target.value)}
                          className="mr-2 ml-2 py-2 px-3 border rounded w-full"
                        />
                        <button
                          type="button"
                          onClick={() => removeInstruction(index)}
                          className="inline-flex items-center justify-center w-6 h-8 mr-2 text-pink-100 transition-colors duration-150 bg-red-500 rounded-lg focus:shadow-outline hover:bg-red-600"
                        >
                          <MdDeleteOutline />
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={addInstruction}
                      className="bg-green-500 text-white py-2 px-2 rounded-full hover:bg-green-600"
                    >
                      <MdAdd />
                    </button>
                  </div>
                </div>
              </div>
            </div>


            <button
              disabled={isLoadingUpdateRecipeDetail}
              type="button"
              onClick={handleUpdateRecipe}
              className="w-full bg-green-500 text-white font-semibold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70 mt-8"
            >
              Update Recipe
            </button>
          </article>
        </section>
      )}
    </div>
  );
};

export default EditRecipe;
